import React, { Component } from 'react';
import "semantic-ui-css/semantic.min.css";
import { Icon } from "semantic-ui-react";

class Card extends Component {
    cardStyle = () => {
        let { pro, small } = this.props
        return {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
            fontSize: small? '12px' : 'auto',
            borderRadius: small? '2px' : "4px",
            height: small? '30px' : '50px',
            width: small? '20px' : '30px',
            margin: small? '2px' : 'auto',
            backgroundColor: pro? "white" : "#AE0000",
            color: pro? "#AE0000" :"white",
        }
      }

    render() {
        return (
            <div>
                <Icon name="flag" style={this.cardStyle()}/>
            </div>
        );
    }
}

export default Card;
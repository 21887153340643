import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import { Button, TextField } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../style/material-ui-theme';


import firebase from "../firebase"
const db = firebase.firestore();

class JoinGame extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.cookies.get('name'),
            code: this.props.code,
            game: this.props.match.params.game ? this.props.match.params.game : this.props.title,
            codeError: false,
            nameError: false,
            nameTakenError: false,
            gameError: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.generateCode = this.generateCode.bind(this);
        this.gameExists = this.gameExists.bind(this);
        this.newGame = this.newGame.bind(this);
    }

    handleChange = (event) => {
        let { name, value } = event.target;
        if (name === "code") {
            if (value.length > 4) {
                value = value.substring(0, 4)
            }
            value = value.toUpperCase()
        }
        event.target.value = value
        this.setState({ [name]: value });
    };

    handleSubmit = async () => {
        console.log(this.state.name + " " + this.state.code + " " + this.props.host)
        this.state.nameError = (this.state.name === "")
        this.state.codeError = (this.state.code.length !== 4)
        if (this.state.nameError | (!this.props.host && this.state.codeError)) {
            this.setState({})
            return
        }
        const { history } = this.props;
        if (history) {
            if (this.props.host === "true") {
                this.generateCode();
            }
            else {
                this.joinGame()
            }
        }
    }

    generateCode = async () => {
        let length = 4;
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var code = '';
        for ( var i = 0; i < length; i++ ) {
            code += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return await this.gameExists(code, this.state.name)
    }

    gameExists = async (code, name) => {
        let newGame = this.newGame.bind(this)
        let generateCode = this.generateCode.bind(this)
        console.log("game exists - name: " + name + " code: " + code)
        db.collection(this.state.game).doc(code).get().then(function(doc) {
            if (doc.exists) {
                console.log("Document data:", doc.data());
                generateCode()
            } else {
                console.log("No such document!");
                newGame(code, name)
            }
        })
        .catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    newGame = async (code, name) => {
        let _this = this
        let player = {   
            name: name,
            score: 0,
            currTurn: true,
            pros: [],
            cons: [],
        } 
        db.collection(this.state.game).doc(code).set({
            host: name,
            players: [player],
            started: false,
        })
        .then(function() {
            console.log("Document successfully written!");
            const { cookies } = _this.props;
            console.log(cookies)
            cookies.set('name', name, { path: '/' });

            const { history } = _this.props;
            let path = _this.props.location.pathname.replace("new-game", code)
            if (history) history.push(path)
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    joinGame = async () => {
        let _this = this
        let code = this.state.code
        let name = this.state.name
        let player = {   
            name: this.state.name,
            score: 0,
            currTurn: false,
            pros: [],
            cons: [],
        } 
        var game = db.collection(this.state.game).doc(code);

        db.runTransaction(transaction => {
            return transaction.get(game)
            .then(snapshot => {
                const playerArray = snapshot.get('players');

                let error = false
                playerArray.forEach((person) => {
                    console.log(person)
                    if (person.name === name) {
                        _this.setState({nameTakenError: true, gameError: false})
                        error = true
                    }
                })

                if(!error) {
                    const { cookies } = _this.props;
                    cookies.set('name', name, { path: '/' });
                    playerArray.push(player);
                    transaction.update(game, 'players', playerArray);
                    
                    const { history } = _this.props;
                    let path = _this.props.location.pathname
                    if (path.includes("join-game")) {
                        if (history) history.push(path.replace("join-game", code));
                    }
                    else {
                        this.props.addPlayer();
                    }
                }
            })
            .catch(function(error) {
                console.error("Error updating document: ", error);
                _this.setState({gameError: true})
            });
        });
        
    }

    render() {
        console.log(this.state)
        let { codeError, nameError, nameTakenError, gameError, code } = this.state
        let host = this.props.host === "true"
        return (
            <div className="join-game"> 
            <div className="join-game-form">                 
                <ThemeProvider theme={theme}>
                    <TextField 
                        id="name" 
                        name="name"
                        error={nameError | nameTakenError}
                        helperText={nameError ? "Please enter your name" : 
                                    nameTakenError ? "Name taken: Please pick another" : ""}
                        color="textPrimary"
                        label="Enter Name" 
                        variant="outlined"
                        defaultValue={this.props.cookies.get('name')}
                        onChange={this.handleChange}
                        className="textField" />
                    {!host && 
                    <TextField 
                        id="game-code" 
                        error={codeError | gameError}
                        name="code"
                        value={code}
                        color="textPrimary"
                        helperText={codeError ? "Please enter 4-letter game code" :
                                    gameError ? "Code Invalid: Game doesn't exist" : ""}
                        label="4-letter Game Code" 
                        variant="outlined"
                        onChange={this.handleChange}
                        style={{marginTop: '10px'}}
                        className="textField" />}
                    <Button 
                        id="enterName" 
                        type="submit" 
                        variant="contained" color="primary"
                        style={{ marginTop: "10px", marginLeft: "5px" }} 
                        onClick={this.handleSubmit}>
                        {host ? "Start Game" : "Join Game"}
                    </Button>
                </ThemeProvider>
            </div>
            </div>
        );
    }
}

export default withCookies(withRouter(JoinGame));



    // getGame = async (code) => {
    //     db.collection("games").doc(code)
    //         .get()
    //         .then(doc => {
    //         if(doc.exists) {
    //             var item = doc.data();
    //             console.log(item)
    //             return item
    //         } else {
    //             console.log("Invalid Code");
    //             return null
    //         }
    //     }).catch(error => {
    //         console.log("Error getting document", error);
    //     });
    // }

    // Add a new document in collection "games"
import React, { Component } from 'react';

import CardFront from "./CardFront";
import SelectDate from "./SelectDate";

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../style/material-ui-theme';

class CurrentPlayer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log(this.props)
        let { name, score, pros, cons, currTurn, prosSelected, 
              consSelected, proTotal, conTotal, submitted, revealed } = this.props.player
        let pro_cards = this.props.pros
        let con_cards = this.props.cons
        let { selectionPhase, players } = this.props.game
        let canSubmit = (proTotal === 2 && conTotal === 1)
        let currDating = players[this.props.game.currTurn].name
        let allRevealed = this.props.allRevealed

        return (
            <div className="current-player">
                 <div className="current-player-info-bar">
                    <div style={{margin: '10px 15px 0px 0px', fontSize: '20px'}}>{name}</div>
                    <p style={{margin: '5px 5px 5px 0px', fontSize: '16px'}}>Dates: {score}</p>
                </div>
                { !currTurn && <div className="player-cards" style={{ paddingLeft: '5px'}}>
                    {pros.map((pro, index) => (
                        <CardFront  selectCard={this.props.selectCard} revealCard={this.props.revealCard} selectionPhase={selectionPhase} 
                                    text={pro_cards[pro]} pro={true} selected={prosSelected[index]} index={index} revealed={revealed}/>
                    ))}
                    {cons.map((con, index) => (
                        <CardFront  selectCard={this.props.selectCard} revealCard={this.props.revealCard} selectionPhase={selectionPhase}
                                    text={con_cards[con]} pro={false} selected={consSelected[index]} index={index} revealed={revealed}/>
                    ))}
                </div>}  
                { !currTurn && selectionPhase && <div style={{width: '200px', marginLeft: '10px', height: '120px', position: 'relative'}}>
                    <div>Select 2 white cards for yourself, and 1 Red Flag for the player next to you</div>
                    <ThemeProvider theme={theme}>  
                        <Button variant="contained" color="secondary" 
                                disabled={!canSubmit} onClick={() => this.props.submitChoices(this.props.player)}
                                className="select-button" style={{bottom: '-10px', padding: '5px 7px'}}>
                                {submitted ? "Resubmit Choices" : "Submit Choices"}
                        </Button>  
                    </ThemeProvider>
                </div>}
                { !currTurn && !selectionPhase && <div style={{width: 'calc(100% - 500px)', textAlign: 'center'}}>Pitch your date to {currDating}</div>}
                { currTurn && selectionPhase && <div style={{width: '100%', textAlign: 'center', marginLeft: '30px'}} >Waiting for the matchmakers to set up your blind date</div>}
                { currTurn && !selectionPhase  && <div style={{width: '200px', marginLeft: '30px'}}>
                    {allRevealed? "Select your favorite proposed date" : "Waiting for everyone to reveal their proposed dates"}</div>}
                { currTurn && !selectionPhase  && <SelectDate chooseDate={this.props.chooseDate} players={players} allRevealed={allRevealed}/>}
            </div>
        );
    }
}

export default CurrentPlayer;

//<button disabled={!canSubmit} className="select-button" style={{bottom: '20px'}}>Submit Choices</button>
                    
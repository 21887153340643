import React, { Component } from 'react';

import Opponent from "./Opponent";
import CurrentPlayer from "./CurrentPlayer";
import Decks from "./Decks";
import GameEnded from "./GameEnded";

import '../../style/components/red-flags.scss';

import firebase from "../../firebase"
const db = firebase.firestore();

class RedFlagsGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: this.props.code,
            game: null,
            currPlayer: null,
            submittedCount: 0,
            lastToSubmit: "",
            allRevealed: false,
        };
    }
    
    componentDidMount = () => {
        this.getGame()
        this.getGameCards()
    }
    
    getGame = async () => {
        let { code } = this.state
        let _this = this

        db.collection("red-flags").doc(code).onSnapshot(doc => {
            if (doc.exists) {
                let game = doc.data()
                console.log(game)
                if (!game.ended && game.needCards) {
                    console.log("needs cards")
                    _this.assignCardsToPlayers(game, game.currTurn)
                }
                else if (!game.ended && !game.selectionPhase) {
                    _this.setState({ game: game })
                    _this.allRevealed()
                }              
                else {
                    _this.setState({game: game})
                }
                _this.setOpponentsAndPlayer(game.players)
            } else {
                console.log("No such document!");
            }
        })
    }

    getGameCards = () => {
        let _this = this
        db.collection("red-flag-cards").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                let id = doc.id
                _this.setState({[id]: doc.data().cards})
            });
        })
        .catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    setOpponentsAndPlayer = (players) => {
        let {name} = this.props
        let opponents = []
        let currPlayer;
        let submittedTotal = 0
        let lastToSubmit = ""
        players.forEach((person) => {
            if (person.submitted) {
                submittedTotal += 1
            }
            else if (!person.currTurn){
                lastToSubmit = person.name
            }
            if (person.name === name) {
                currPlayer = person
            }
            else {
                opponents.push(person)
            }
        })
        this.setState({ opponents: opponents, currPlayer: currPlayer, 
                        submittedCount: submittedTotal, lastToSubmit: lastToSubmit} )
    }

    countGameCards = (game) => {
        let {pros, cons, discardedPros, discardedCons} = game
        let gameEnded = false
        if (pros.length < (game.players.length - 1)*3) {
            if ((discardedPros.length + pros.length) < (game.players.length - 1)*3) {
                game.ended = true
                gameEnded = true
            }
            else {
                this.shuffleDeck(discardedPros)
                let new_deck = pros.concat(discardedPros)
                game.pros = new_deck
                game.discardedPros = []
            }
        }
        if (!gameEnded && (cons.length < (game.players.length - 1)*2)) {
            if ((discardedCons.length + cons.length) < (game.players.length - 1)*2) {
                game.ended = true
                gameEnded = true
            }
            else {
                this.shuffleDeck(discardedCons)
                let new_deck = cons.concat(discardedCons)
                game.cons = new_deck
                game.discardedCons = []
            }
        }
        if (gameEnded) {
            db.collection("red-flags").doc(this.state.code).update({
                gameEnded: true,
            }, { merge: true })
            .then(function(doc) {
                console.log("Game successfully ended!");
            })
            .catch(function(error) {
                console.error("Error updating document: ", error);
            });
        }
        return gameEnded
    }

    assignCardsToPlayers = (game, currTurn) => {
        let gameEnded = this.countGameCards(game)

        if (!gameEnded) {
            game.players.forEach((person, index) => {
                if (index !== currTurn) {
                    this.assignCards(person, game)
                }
            })
            db.collection("red-flags").doc(this.state.code).update({
                pros: game.pros,
                cons: game.cons,
                players: game.players,
                needCards: false,
            }, { merge: true })
            .then(function(doc) {
                console.log("Cards successfully passed out!");
            })
            .catch(function(error) {
                console.error("Error updating document: ", error);
            });
        }
        
        this.setState({game: game})
    }

    assignCards = (person, game) => {
        let {pros, cons} = game
        let player_pros = []
        let player_cons = []
        for (let i = 0; i < 3; i++){
            player_pros.push(pros.pop())
        }
        for (let i = 0; i < 2; i++){
            player_cons.push(cons.pop())
        }
        person.cons = player_cons
        person.pros = player_pros
        person.prosSelected = [false, false, false]
        person.consSelected = [false, false]
        person.proTotal = 0
        person.conTotal = 0
        person.submitted = false
        person.revealed = [false, false, false]
    }

    shuffleDeck = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }
    
    selectCard = (isPro, index) => {
        let player = this.state.currPlayer
        let selected = isPro? player.prosSelected : player.consSelected
        let chosen = selected[index]
        var total = isPro ? player.proTotal : player.conTotal
        if (chosen) {
            total -= 1
            selected[index] = false
        }
        else {
            let maxTotal = isPro? 2 : 1
            if (total < maxTotal) {
                total += 1
                selected[index] = true
            }
        }
        isPro ? player.proTotal = total : player.conTotal = total
        this.setState({currPlayer: player}) 
    }

    submitChoices = (player) => {
        let _this = this
        player.submitted = true

        let code = this.props.code
        var game = db.collection("red-flags").doc(code);

        db.runTransaction(transaction => {
            return transaction.get(game)
            .then(snapshot => {
                const playerArray = snapshot.get('players');
                let discardedPros = snapshot.get('discardedPros')
                let discardedCons = snapshot.get('discardedCons')
                playerArray.forEach((person, index) => {
                    if (person.name === player.name) {
                        playerArray[index] = player
                    }
                })
                if (_this.state.submittedCount + 2 === playerArray.length) {
                    _this.removeUnselected(playerArray, discardedPros, discardedCons)
                    _this.exchangeRedFlags(playerArray)
                    transaction.update(game, 'discardedPros', discardedPros); 
                    transaction.update(game, 'discardedCons', discardedCons); 
                    transaction.update(game, 'selectionPhase', false); 
                }
           
                transaction.update(game, 'players', playerArray);               
            })
            .catch(function(error) {
                console.error("Error updating document: ", error);
            });
        });
    }

    exchangeRedFlags = (players) => {
        let previousFlags = null
        let firstPlayer = null
        console.log(players)
        players.forEach((player, index) => {
            if (!player.currTurn) {
                if (previousFlags === null) {
                    firstPlayer = player
                    previousFlags = player.cons
                }
                else {
                    let temp = player.cons
                    player.cons = previousFlags
                    previousFlags = temp
                }   
            }
        })
        firstPlayer.cons = previousFlags
        console.log(players)
    }

    removeUnselected = (players, discardedPros, discardedCons) => {
        players.forEach((player) => {
            if (!player.currTurn) {
                let { pros, prosSelected, cons, consSelected } = player
                prosSelected.forEach((selected, index) => {
                    if (!selected) {
                        // Remove item from pros array and add to discarded pros array 
                        discardedPros.push(pros[index])
                        pros.splice(index, 1)
                    }
                })
                consSelected.forEach((selected, index) => {
                    if (!selected) {
                        // Remove item from cons array and add to discarded cons array 
                        discardedCons.push(cons[index])
                        cons.splice(index, 1)
                    }
                })
            }
        })
    }

    chooseDate = (selected) => {
        if (selected !== "Select") {        
            var game = db.collection("red-flags").doc(this.state.code);
            db.runTransaction(transaction => {
                return transaction.get(game)
                .then(snapshot => {
                    const playerArray = snapshot.get('players');
                    let round = snapshot.get('round');
                    let currTurn = snapshot.get('currTurn');
                    let newCurrTurn = (currTurn + 1) % playerArray.length
                    if (currTurn === 0){ 
                        transaction.update(game, 'round', round++) 
                    }
                    playerArray.forEach((person, index) => {
                        if (index === currTurn) {
                            person.currTurn = false
                        }
                        else if (index === newCurrTurn) {
                            person.currTurn = true
                            person.submitted = false
                            person.pros = []
                            person.cons = []
                        }
                        if (person.name === selected) {
                            person.score += 1
                        }
                    })
            
                    transaction.update(game, 'players', playerArray); 
                    transaction.update(game, 'currTurn', newCurrTurn);   
                    transaction.update(game, 'needCards', true);   
                    transaction.update(game, 'selectionPhase', true);               
                })
                .catch(function(error) {
                    console.error("Error updating document: ", error);
                });
            });
            console.log(this.state)
        }
    }

    revealCard = (isPro, index) => {
        let player = this.state.currPlayer
        player.revealed[isPro? index : index + 2] = true
        var game = db.collection("red-flags").doc(this.state.code);

        db.runTransaction(transaction => {
            return transaction.get(game)
            .then(snapshot => {
                const playerArray = snapshot.get('players');
                playerArray.forEach((person, index) => {
                    if (person.name === player.name) {
                        playerArray[index] = player
                    }
                })           
                transaction.update(game, 'players', playerArray);               
            })
            .catch(function(error) {
                console.error("Error updating document: ", error);
            });
        }); 
    }

    allRevealed = () => {
        let players = this.state.game.players
        let allRevealed = true
        players.forEach((player) => {
            if (!player.currTurn) {
                player.revealed.forEach((card) => {
                    if (!card) {
                        allRevealed = false
                    }
                })
            }
        })
        this.setState({allRevealed: allRevealed})
    }

    render() {
        //console.log(this.props)
        //console.log(this.state)
        let name = this.props.name
        let {opponents, game, currPlayer, submittedCount, lastToSubmit, pros, cons, allRevealed } = this.state
        let waitingCount = game? game.players.length - submittedCount - 1 : 0
        let waitingMessage = (waitingCount === 1) ? "Waiting for " + lastToSubmit : "Waiting for " + waitingCount + " players"
        let currDating = game? game.players[game.currTurn].name : ""
        if (waitingCount == 0) {
            waitingMessage = allRevealed? "Waiting for " + currDating + " to select their date" :
                                          "Listen to the matchmakers pitch their dates"
        }
        let ended = game? game.ended : false
        // let playerRows = Math.floor((players.length-1)/5) + 1
        // console.log(playerRows)
        return (
            <div>
                {!ended && <div className="red-flags-game">
                    <div className="player-profile-row">
                        {game && pros && game.players.filter(function (person) { return person.name !== name}).map((player, index) => (
                            <Opponent selectionPhase={game.selectionPhase} player={player} key={index} pros={pros} cons={cons}/>
                        ))}
                    </div>
                    {game && <Decks game={game} message={waitingMessage}/>}
                </div>}
                {game && !ended && currPlayer && pros && <CurrentPlayer game={game} player={currPlayer} pros={pros} cons={cons}
                    allRevealed={allRevealed} selectCard={this.selectCard} submitChoices={this.submitChoices} 
                    revealCard={this.revealCard} chooseDate={this.chooseDate}/>}
                {game && ended && <GameEnded players={game.players} name={name}/>}
           </div>
        );
    }
}

export default RedFlagsGame;


// addCards = async () => {
//     // Update new cards
//     let new_cards = [
//         "Buys you flowers",
//         "Does sweet gestures without being asked",
//         "Is supportive through good and bad times",
//         "Always sends good morning and good night texts",
//         "Can talk to them about anything",
//         "Doesn’t judge you"
//     ]
//     // Choose to add to pros or cons
//     var deck = db.collection("red-flag-cards").doc("pros");

//     db.runTransaction(transaction => {
//         return transaction.get(deck)
//         .then(snapshot => {
//             const cards = snapshot.get('cards');
//             let new_deck = cards.concat(new_cards)
//             transaction.update(deck, 'cards', new_deck);
//         })
//         .catch(function(error) {
//             console.error("Error updating document: ", error);
//         });
//     });
// }
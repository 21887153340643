import React, { Component } from 'react';

class GameEnded extends Component {
    render() {
        let players = this.props.players
        let currPlayer = this.props.name
        players.sort((p1, p2) => (p2.score >= p1.score) ? 1 : -1)
        const items = players.map((person, key) => {
            return  <div className="player-score" key={key} style={{color: person.name===currPlayer ? '#4ecca3' : 'white'}}>
                        <div style={{float: "left", width: "100%"}}>{person.name}</div>
                        <div style={{float: "right"}}>{person.score}</div>
                    </div>;
        });

        return (
            <div className="player-list">
                <div className="player-title">Game Over</div>
                <div className="player-subtitle">Successful Matches</div>
                <div>
                    {items}
                </div>
                <div style={{height: '40px'}}></div>
            </div>
        );
    }
}

export default GameEnded;
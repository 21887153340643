import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
          main: '#4ecca3',
        },
        secondary: {
          light: '#eeeeee',
          main: '#eeeeee',
        },
        info: {
          main: 'rgb(163, 19, 19)',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "#eeeeee",
        },
      },
  });

export const red_flags_theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
          main: 'rgb(163, 19, 19)',
        },
        secondary: {
          light: '#eeeeee',
          main: '#eeeeee',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
            primary: "#eeeeee",
        },
      },
  });

import React from "react";
import PropTypes from "prop-types";

import backCardImg from "../../style/images/card_back.jpg";
import heart from "../../style/images/heart.png";
import diamond from "../../style/images/diamond.png";
import club from "../../style/images/club.png";
import spade from "../../style/images/spade.png";

import "../../style/components/card.scss";

const Card = (props) => {

  const { suits, card, front, color } = props;
  const deck = props.deck ? (front ? '-185px' : '-200px'): '-160px';

  const getCardSymbol = (suits) => {
    let symbol;
    switch(suits) {
      case "Diamond":
        return symbol = diamond;
      case "Heart":
        return symbol = heart;
      case "Club":
        return symbol = club;
      case "Spade":
        return symbol = spade;
      default:
        return symbol;
    };
  };

  if(front) {
    const cardSymbol = getCardSymbol(suits);
    return (
      <div className="card-container" style={{ color: `${color}`, marginLeft: `${deck}` }}>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <div style={{ maxWidth: 30, fontSize: "larger", paddingBottom: '5px' }}>{card}</div>
          <img src={cardSymbol} alt="suit-symbol" style={{ maxWidth: 24 }}/>
        </div>
        <div>
          <img src={cardSymbol} alt="suit-symbol" style={{ height: 50, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}/>
        </div>
        <div style={{ position: "absolute", bottom: 10, right: 10, transform: "rotate(-180deg)" }}>
          <div style={{ maxWidth: 30, fontSize: "larger", paddingBottom: '5px' }}>{card}</div>
          <img src={cardSymbol} alt="suit-symbol" style={{ maxWidth: 24 }}/>        
        </div>
      </div> 
    );
  } else {
    return (
      <div className="card-container" style={{ backgroundImage: `url(${backCardImg})`, color: `${color}` }}></div>
    );
  };
};

Card.propTypes = {
  suits: PropTypes.string,
  card: PropTypes.string,
  front: PropTypes.bool,
  color: PropTypes.string
};

export default Card;

import React, { Component } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter } from "react-router-dom";

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../style/material-ui-theme';

import PlayerList from "./PlayerList";
import ChangePlayer from "./ChangePlayer";
import Durak from "./durak/Durak";
import RedFlagsGame from "./red_flags/RedFlagsGame";
import JoinGame from "./JoinGame";

import { instanceOf } from 'prop-types';
import firebase from "../firebase"
const db = firebase.firestore();

class GameMaster extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        this.state = {
            code: this.props.match.params.code,
            title: this.props.title,
            game: null,
            started: false,
            gameError: false,
            host: null,
            name: this.props.cookies.get('name'),
        };
    }
    
    changePlayer = (playerName) => {
        const { cookies } = this.props;
        cookies.set('name', playerName, { path: '/' });
        this.setState({ name: playerName });
    };

    componentDidMount() {
        this.getGame();
    }

    getGame = async () => {
        let { code, title } = this.state
        let _this = this

        db.collection(title).doc(code).onSnapshot(doc => {
            if (doc.exists) {
                let game = doc.data()
                _this.playerExists(game)
            } else {
                console.log("No such document!");
                _this.setState({gameError: true})
            }
        })
    }

    playerAdded = () => {
        window.location.reload();
        //this.setState({playerExists: true})
    }

    playerExists = (game) => {
        let exists = false
        game.players.forEach((player) => {
            if (player.name === this.state.name) {
                exists = true
            }
        })
        this.setState({ game: game, players: game.players, started: game.started, 
                        host: game.host, playerExists: exists})
    }

    returnHome = () => {
        const { history } = this.props;
        if (history) history.push("/");
    }

    start = () => {
        if (this.state.title === "red-flags") {
            this.startRedFlags()
        }
    }

    startRedFlags = () => {
        const pro_length = 106
        const con_length = 76
        let pros = [...Array(pro_length).keys()]
        let cons = [...Array(con_length).keys()]
        this.shuffleDeck(pros)
        this.shuffleDeck(cons)
        
        db.collection("red-flags").doc(this.state.code).update({
            started: true,
            selectionPhase: true,
            needCards: true,
            pros: pros,
            cons: cons,
            discardedPros: [],
            discardedCons: [],
            currTurn: 0,
            round: 0,
        }, { merge: true })
        .then(function(doc) {
            console.log("Game successfully started!");
        })
        .catch(function(error) {
            console.error("Error updating document: ", error);
        });
    }

    shuffleDeck = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    render() {
        console.log(this.state)
        let { playerExists, code, players, gameError, host, name, started, title } = this.state
        let enoughPlayers, message = ""
        let hosting = (host === name)
        // delete after testing
        // hosting = true
        if (players) {
            enoughPlayers = players.length > 2
            message = hosting? enoughPlayers ? "Start" : "Need 3 players to start" : "Waiting for host"
        }
        return (
            <div className="game-master">
                {playerExists && !started && <div className="gameCode">Game Code: {code}</div>}
                {gameError && 
                <div style={{textAlign: "center"}}>
                    <div className="gameError">Error: Game could not be found</div>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color="primary" style={{width: "250px", marginBottom: '10px'}} onClick={this.returnHome}>Back to Home</Button>
                    </ThemeProvider>
                </div>}

                {!gameError && !started && players && playerExists && 
                    <div>
                        <PlayerList players={players} name = {name}/>
                        <ThemeProvider theme={theme}>    
                            <Button variant="contained" color="secondary" style={{width: "100%", position: 'fixed', height: '40px', bottom: 42, left: 0}} onClick={this.returnHome}>Leave</Button>
                            <Button variant="contained" disabled={!hosting | !enoughPlayers } color="primary" style={{width: "100%", height: '40px', position: 'fixed', bottom: 0, left: 0}} onClick={this.start}>
                                {message}
                            </Button>
                        </ThemeProvider>
                    </div>}

                {!gameError && !started && players && !playerExists && 
                    <JoinGame host="false" code={code} title={title} addPlayer={this.playerAdded}/>
                }

                {!gameError && started && 
                <div>
                    {title === "Durak" && <Durak/>}
                    {title === "red-flags" && <RedFlagsGame name={name} code={code}/>}
                </div>}
            </div>
        );
    }
}

export default withCookies(withRouter(GameMaster));

//{playerExists && players && <div><ChangePlayer players={players} changePlayer={this.changePlayer}/></div>}
                
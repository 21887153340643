import React, { Component } from 'react';

import Card from "./Card" ;

class Hand extends Component {
    render() {
        let { cards, front, deck, start } = this.props
        deck = (deck | deck === "true")
        front = (front | front === "true")
        let hideDeck = deck && !front && start
        
        return (
            <div style={{ display: "flex", justifyContent: "center", margin: "40px auto 0px 180px" }}>
                {hideDeck && <Card deck={deck} suits="Spade" card="A" color="black" front={front}/>}
                {!hideDeck && cards.map((card, index) => {
                    return (
                    <div className="animated slideInUp" key={index}>
                        <Card deck={deck} suits={card.suits} card={card.card} color={card.color} front={front}/>
                    </div>
                    ); 
                })}
            </div>
        );
    }
}

export default Hand;
import firebase from "firebase";
const config = {
    apiKey: "AIzaSyDyxliQJAsam-sxfSgL-evND9dsExFE1v0",
    authDomain: "durak-4e89f.firebaseapp.com",
    databaseURL: "https://durak-4e89f.firebaseio.com",
    projectId: "durak-4e89f",
    storageBucket: "durak-4e89f.appspot.com",
    messagingSenderId: "176896107599",
    appId: "1:176896107599:web:7442c13e4d8052f25d763a",
    measurementId: "G-BYT7F4Z88E"
  };
firebase.initializeApp(config);
export default firebase;
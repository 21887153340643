import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../style/material-ui-theme';

class HomePage extends Component {
    play = (name) => {
        const { history } = this.props;
        if (history) history.push("/" + name);
    }

    render() {
        return (
            <div className="homepage">
                <div className="gameTitle">Gamers-R-Us</div>              
                <ThemeProvider theme={theme}>
                    <Button variant="contained" color="primary" style={{width: "250px", marginBottom: '10px'}} onClick={() => this.play("decryptor")}>Decrytor</Button>
                    <Button variant="contained" color="primary" style={{width: "250px", marginBottom: '10px'}} onClick={() => this.play("durak")}>Durak</Button>
                    <Button variant="contained" color="primary" style={{width: "250px"}} onClick={() => this.play("red-flags")}>Red Flags</Button>
                </ThemeProvider>
            </div>
        );
    }
}

export default withRouter(HomePage);
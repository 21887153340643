import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../style/material-ui-theme';

class StartGame extends Component {
    newGame = () => {
        const { history } = this.props;
        let loc = this.props.location.pathname
        if (history) history.push(loc + "/new-game");
    }

    joinGame = () => {
        const { history } = this.props;
        let loc = this.props.location.pathname
        if (history) history.push(loc + "/join-game");
    }

    render() {
        let title = this.props.title
        return (
            <div className="homepage">
                <div className="gameTitle">{title}</div>              
                <ThemeProvider theme={theme}>
                    <Button variant="contained" color="primary" style={{width: "250px", marginBottom: '10px'}} onClick={this.newGame}>New Game</Button>
                    <Button variant="contained" color="primary" style={{width: "250px"}} onClick={this.joinGame}>Join Game</Button>
                </ThemeProvider>
            </div>
        );
    }
}

export default withRouter(StartGame);
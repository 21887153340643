import React, { Component } from 'react';

import Card from "./Card";
import RevealedCard from "./RevealedCard";

class Opponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {name, score, pros, cons, currTurn, revealed} = this.props.player
        let proList = this.props.pros
        let conList = this.props.cons
        let revealPhase = !this.props.selectionPhase
        console.log("name: " + name)
        //console.log((!revealPhase || !revealed[1]))
        return (
            <div className="player-profile">
                <div className="player-info-bar">
                    <div style={{float: "left", width: "100%"}}>{name}</div>
                    <div style={{float: "right"}}>{score}</div>
                </div>
                <div className="player-cards" style={{flexDirection: revealPhase? "column": "row"}}>
                    {pros.map((pro, index) => (
                        <div>
                        {(!revealPhase || !revealed[index]) && <Card pro={true} key={index} small={true}/>}
                        {revealPhase && revealed[index] && <RevealedCard text={proList[pro]} pro={true}/>}
                        </div>
                    ))}
                    {cons.map((con, index) => ( 
                        <div>
                        {(!revealPhase || !revealed[index+2])  && <Card pro={false} key={index} small={true}/>}
                        {revealPhase && revealed[index+2] && <RevealedCard text={conList[con]} pro={false}/>}
                        </div>
                    ))}
                </div>
                {currTurn && <div style={{height: '30px'}}>Looking for a date</div>}
            </div>
        );
    }
}

export default Opponent;
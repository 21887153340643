import React, { Component } from 'react';

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import { Select, Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { red_flags_theme } from '../../style/material-ui-theme';

class SelectDate extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            players: this.props.players,
            name: this.props.cookies.get('name'),
            selected: "Select",
        };
    }

    handleChange = (event) => {
        this.setState({ selected: event.target.value });
    }

   

    render() {
        let { name, selected } = this.state
        let { players } = this.props
        let allRevealed = this.props.allRevealed
        return (
            <div style={{marginBottom: "10px"}}>
                <ThemeProvider theme={red_flags_theme}> 
                <Select
                    native
                    value={selected}
                    disabled={!allRevealed}
                    onChange={this.handleChange}
                    style={{padding: '3px'}}
                    inputProps={{ name: 'playerNum',  id: 'player-num'}}
                    >
                    <option value="Select" key={0} hidden> 
                        Select 
                    </option> 
                    {players.filter(function (person) { 
                        return person.name !== name 
                    }).map(function (person, index) {
                        return <option value={person.name} key={index+1}>{person.name}</option>
                    })}
                </Select>
                <Button variant="contained" color="primary" style={{marginLeft: '10px'}} 
                        onClick={() => this.props.chooseDate(selected)}>
                    Submit
                </Button>
                </ThemeProvider>
            </div>
        );
    }
}

export default withCookies(SelectDate);
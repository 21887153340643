import React, { Component } from 'react';

//import firebase from "../firebase"
//const db = firebase.firestore();

class PlayerList extends Component {

    render() {
        let { players } = this.props
        let currPlayer = this.props.name
        const items = players.map((person, key) => {
            return <li key={key} style={{color: person.name===currPlayer ? '#4ecca3' : 'white', padding: '3px'}}>{person.name}</li>;
        });

        return (
            <div className="player-list">
                <div className="player-title">Players</div>
                <ol>
                    {items}
                </ol>
                <div style={{height: '90px'}}></div>
            </div>
        );
    }
}

export default PlayerList;
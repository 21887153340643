import React, { Component } from "react";
import {  BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

import Durak from "./components/durak/Durak";
import HomePage from "./components/HomePage";
import JoinGame from "./components/JoinGame";
import GameMaster from "./components/GameMaster";
import StartGame from "./components/StartGame";
import InDevelopment from "./components/InDevelopment";

import './style/components/home.scss';

class App extends Component {

  constructor() {
    super();
    this.state = {
      players: 2
    };
  };

  render() {

    return (
      <div style={{ width: "100%" }}>
        <CookiesProvider>
          <Router>
              <Switch>
                <Route path="/:game/join-game"><JoinGame host="false" code=""/></Route>
                <Route path="/:game/new-game"><JoinGame host="true" code=""/></Route>
                <Route path="/red-flags/:code"><GameMaster title="red-flags"/></Route>
                <Route path="/red-flags"><StartGame title="Red Flags"/></Route>
                <Route path="/durak"><InDevelopment/></Route>
                <Route path="/decryptor"><InDevelopment/></Route>
                <Route><HomePage/></Route>
              </Switch>
            </Router>
          </CookiesProvider>
      </div>
    );
  };
};

// <Route path="/decryptor/:code"><GameMaster title="Decryptor"/></Route>
// <Route path="/decryptor"><StartGame title="Decryptor"/></Route>
// <Route path="/durak/:code"><Durak/></Route>
// <Route path="/durak"><StartGame title="Durak/Дурак"/></Route>

export default App;

import React, { Component } from 'react';

import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import { Select } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../style/material-ui-theme';

class ChangePlayer extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            players: this.props.players,
            player: this.props.player,
            name: this.props.cookies.get('name')
        };
    }

    handleChange = (event) => {
        console.log("changing player")
        this.props.changePlayer(event.target.value)
        this.setState({ name: event.target.value });
    };

    render() {
        let {name, players} = this.state
        return (
            <div style={{marginBottom: "10px"}}>
                <ThemeProvider theme={theme}> 
                    <Select
                        native
                        value={name}
                        onChange={this.handleChange}
                        inputProps={{ name: 'playerNum',  id: 'player-num'}}
                        >
                        {players.map((person, index) => (
                            <option value={person.name} key={index}>{person.name}</option>
                        ))}
                    </Select>
                </ThemeProvider>
            </div>
        );
    }
}

export default withCookies(ChangePlayer);
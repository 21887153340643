import React from "react";
import PropTypes from "prop-types";

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../style/material-ui-theme';

const ActionsButtons = (props) => {
  return (
    <div style={{ margin: "40px auto", textAlign: "center" }}>
      <ThemeProvider theme={theme}>
        <Button variant="contained" color="secondary" style={{margin: '5px'}} onClick={() => props.shuffle(props.deckArray)}>Shuffle</Button>
        <Button variant="contained" color="secondary" style={{margin: '5px'}} onClick={() => props.dealOneCard(0)}>Deal one card</Button>
        <Button variant="contained" color="secondary" style={{margin: '5px'}}onClick={() => props.flip()}>Flip</Button>
        <Button variant="contained" color="secondary" style={{margin: '5px'}}onClick={() => props.start()}>Start</Button>
      </ThemeProvider>
    </div>
  );
};

ActionsButtons.propTypes = {
  shuffle: PropTypes.func,
  dealOneCard: PropTypes.func,
  flip: PropTypes.func,
  deckArray: PropTypes.array
};

export default ActionsButtons;

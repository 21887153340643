import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../style/material-ui-theme';

class InDevelopment extends Component {
    returnHome = () => {
        const { history } = this.props;
        if (history) history.push("/");
    }

    render() {
        return (
            <div style={{padding: "20px"}}>
                <div style={{textAlign: "center", color: "white"}}>
                    <div className="gameError">Game is currently in development, please try again later</div>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color="primary" style={{width: "250px", marginBottom: '10px'}} onClick={this.returnHome}>Back to Home</Button>
                    </ThemeProvider>
                </div>
            </div>
        );
    }
}

export default withRouter(InDevelopment);
import React, { Component } from 'react';
import "semantic-ui-css/semantic.min.css";

import Card from "./Card";

class CardDeck extends Component {

    render() {
        let { pro, size } = this.props
        return (
            <div className="card-deck">
                <Card pro={pro} small={false}/>
                <div style={{marginTop: "2px"}}>{size} Cards</div>
            </div>
        );
    }
}

export default CardDeck;
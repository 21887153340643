import React, { Component } from 'react';

import { Select, Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { red_flags_theme } from '../../style/material-ui-theme';

class CardFront extends Component {

    cardStyle = () => {
        let pro = this.props.pro
        return {
            padding: '7px',
            margin: '5px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

            borderRadius: "4px",
            height: '120px',
            maxWidth: '120px',
            minWidth: '100px',
            backgroundColor: pro? "white" : "#AE0000",
            color: pro? "black" :"white",
            fontSize: '13px',
        }
    }

    render() {
        let {pro, text, index, selected, selectionPhase } = this.props
        let revealed = false
        if (!selectionPhase) {
            revealed = this.props.revealed[pro? index : index + 2]
        }
        return (
            <div style={this.cardStyle()}>
                <div style={{width: '90%'}}>{text}</div>
                {selectionPhase && <button className="select-button" onClick={() => this.props.selectCard(pro, index)}>
                    {selected? "Deselect" : "Select" }
                </button>}
                {!selectionPhase && <button className="select-button" disabled={revealed} onClick={() => this.props.revealCard(pro, index)}>
                    {revealed ? "Revealed" : "Reveal"}
                </button>}
            </div>
        );
    }
}

export default CardFront;

// Kurfess 480 570 

{/* <ThemeProvider theme={red_flags_theme}> 
    {selectionPhase && <Button variant="contained" color={pro? "primary": "secondary"} className="select-button" onClick={() => this.props.selectCard(pro, index)}>
    {selected? "Deselect" : "Select" }
</Button>}
{!selectionPhase && <Button variant="contained" color={pro? "primary": "secondary"} className="select-button" disabled={revealed} onClick={() => this.props.revealCard(pro, index)}>
    Reveal
</Button>}
</ThemeProvider> */}
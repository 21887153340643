import React, { Component } from 'react';
import CardDeck from "./CardDeck";

class Decks extends Component {
    render() {
        let {game} = this.props
        return (
            <div className="card-decks">
                <CardDeck pro={true} style={{float: 'left'}} small={false} size={game.pros.length}/>
                <div style={{float: 'center'}}>{this.props.message}</div>
                <CardDeck pro={false} style={{float: 'right'}} small={false} size={game.cons.length}/>
            </div>
        );
    }
}

export default Decks;
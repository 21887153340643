import React, { Component } from "react";

import Hand from "./Hand" ;
import ActionsButtons from "./ActionsButtons" ;
import { deckArray, additionalCards } from "../../utils/DeckArray";

import { Button } from '@material-ui/core';

import '../../style/components/game.scss';

class Durak extends Component {

  constructor() {
    super();
    this.state = {
      cardsArray: deckArray,
      playerCards: [],
      front: true,
      start: false,
      players: 2
    };
  };

  shuffle = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    };
    this.setState({ cardsArray: array, cardPicked: [] })
    return array;
  };

  dealOneCard = (player) => {
    let cardsArray = this.state.cardsArray;
    const randomItem = cardsArray[Math.floor(Math.random()*cardsArray.length)];
    const newCardsArray = cardsArray.filter(element => element.index !== randomItem.index)
    this.state.cardsArray = newCardsArray;

    let hand = this.state.playerCards[player]
    if(hand === undefined){
      hand = []
    }
    hand.push(randomItem);
    this.state.playerCards[player] = hand
    this.setState({})
  };

  flip = () => {
    this.setState({ front: !this.state.front})
  };

  incPlayers = () => {
    let num = this.state.players + 1
    if (num <= 6){
      this.setState({ players: num})
    }
    if (num === 5){
      this.multiPlayerMode(true)
    }
  };

  decPlayers = () => {
    let num = this.state.players - 1
    if (num >= 2){
      this.setState({ players: num})
    }
    if (num === 4){
      this.multiPlayerMode(false)
    }
  };

  multiPlayerMode = (multiPlayer) => {
    if(multiPlayer) {
      this.setState({ cardsArray: (deckArray.concat(additionalCards))})
    }
    else {
      this.setState({ cardsArray: deckArray})
    }
  };

  start = () => {
    this.setState({front: false, start: true})
    for (let i = 0; i < 6; i++){
      this.dealOneCard(0)
    }
    for (let i = 0; i < 6; i++){
      this.dealOneCard(1)
    }
  }

  render() {
    const { cardsArray, playerCards, front, start } = this.state

    return (
      <div className="gameBoard" style={{ width: "100%" }}>
        {playerCards[1] && <Hand cards={playerCards[1]} front="false" deck="false"></Hand>}
        {cardsArray  && <Hand cards={cardsArray} front={front} deck="true" start={start}></Hand>}

        <div className="numPlayers">Number of Players: 
            <Button style={{marginLeft: '10px', minWidth: '15px'}} onClick={() => this.decPlayers()}>-</Button>
            <h3 style={{padding: '0px 10px'}}>{this.state.players}</h3>
            <Button style={{minWidth: '15px'}} onClick={() => this.incPlayers()}>+</Button>
        </div>
        <ActionsButtons shuffle={this.shuffle} dealOneCard={this.dealOneCard} flip={this.flip}
                        players={this.state.players} deckArray={deckArray} multiPlayer={this.multiPlayerMode}
                        incPlayers={this.incPlayers} decPlayers={this.decPlayers} start={this.start}/>
        {playerCards[0] && <Hand cards={playerCards[0]} front="true" deck="false"></Hand>}
      </div>
    );
  };
};


export default Durak;

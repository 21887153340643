import React, { Component } from 'react';

class RevealedCard extends Component {
    cardStyle = () => {
        let { pro } = this.props
        return {
            fontSize: '12px',
            borderRadius: '2px',
            //height: '30px',
            minWidth: '116px',
            padding: '5px',
            margin: '2px 0px',
            lineHeight: '1.2',
            backgroundColor: pro? "white" : "#AE0000",
            color: pro? "black" :"white",
        }
    }

    render() {
        return (
            <div style={this.cardStyle()}>
                {this.props.text}
            </div>
        );
    }
}

export default RevealedCard;